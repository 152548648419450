// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-prostate-101-js": () => import("./../../../src/pages/prostate-101.js" /* webpackChunkName: "component---src-pages-prostate-101-js" */),
  "component---src-pages-prostate-mri-js": () => import("./../../../src/pages/prostate-mri.js" /* webpackChunkName: "component---src-pages-prostate-mri-js" */),
  "component---src-pages-tulsa-case-study-js": () => import("./../../../src/pages/tulsa/case-study.js" /* webpackChunkName: "component---src-pages-tulsa-case-study-js" */),
  "component---src-pages-tulsa-faqs-js": () => import("./../../../src/pages/tulsa/faqs.js" /* webpackChunkName: "component---src-pages-tulsa-faqs-js" */),
  "component---src-pages-tulsa-hospitality-js": () => import("./../../../src/pages/tulsa/hospitality.js" /* webpackChunkName: "component---src-pages-tulsa-hospitality-js" */),
  "component---src-pages-tulsa-js": () => import("./../../../src/pages/tulsa.js" /* webpackChunkName: "component---src-pages-tulsa-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-doctors-js": () => import("./../../../src/templates/doctors.js" /* webpackChunkName: "component---src-templates-doctors-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-prostate-js": () => import("./../../../src/templates/prostate.js" /* webpackChunkName: "component---src-templates-prostate-js" */),
  "component---src-templates-tulsa-js": () => import("./../../../src/templates/tulsa.js" /* webpackChunkName: "component---src-templates-tulsa-js" */)
}

